@import "./assets/scss/variables";
@import "./assets/scss/theme";

html {
  overflow-y: auto;
  overflow-x: hidden;
}

body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.scrollable-element::-webkit-scrollbar {
  display: none;
}

body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background-color: #f2f2f2;
}

body::-webkit-scrollbar-thumb {
  background-color: #999999;
  border-radius: 6px;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #666666;
}

html {
  scroll-behavior: smooth;
}
div:where(.swal2-container) {
  display: grid;
  position: fixed;
  z-index: 10600 !important;
  inset: 0;
  box-sizing: border-box;
  grid-template-areas: "top-start     top            top-end" "center-start  center         center-end" "bottom-start  bottom-center  bottom-end";
  grid-template-rows: minmax(min-content, auto) minmax(min-content, auto) minmax(
      min-content,
      auto
    );
  height: 100%;
  padding: 0.625em;
  overflow-x: hidden;
  transition: background-color 0.1s;
  -webkit-overflow-scrolling: touch;
}
.termsList {
  padding-left: 0;
  li {
    font-family: "Mulish", sans-serif !important;
    font-weight: 600;
    margin-left: 0.5em;
    font-size: 0.9rem;
    color: rgb(246, 246, 246);
    padding-bottom: 0.6em;
    text-align: justify;
    list-style: none;
  }
}
.player-wrapper {
  position: relative;
  border-radius: 18px !important;
  padding-top: 56.25%; /* 16:9 aspect ratio (height/width * 100%) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (min-width: 1200px) {
  .player-wrapper {
    width: 1280px;

    height: 720px;
    padding-top: 56.25%; /* 16:9 aspect ratio (height/width * 100%) */
  }
}
@media (max-width: 600px) {
  .player-wrapper {
    padding-top: 40%; /* 4:3 aspect ratio (height/width * 100%) */
  }
}

@media (max-width: 1200px) {
  .player-wrapper {
    width: 854px;
    height: 480px;
    padding-top: 30%; /* Adjust as needed for the desired aspect ratio at this breakpoint */
  }
}
@media (max-width: 600px) {
  .player-wrapper {
    width: 426px;
    height: 240px;
  }
}
.react-player {
  border-radius: 18px !important;
}
.faded {
  color: rgba(0, 0, 0, 0.5);
}

.footer {
  margin: 20px;
}
